<template>
  <div>
    <ListingFilters
      class="position-sticky listings__filters-main z-index-100"
      @on-filter="getListings($event, true)"
    />
    <Spinner v-if="loading" />
    <div class="info-windows" id="map" v-else></div>
    <div class="listingPopup" v-if="listing">
      <Listing
        :listing="listing"
        :externalClass="['overflow-hidden']"
        :listingLink="listing.reacted ? '/listings/saved' : `/listings/${listing.id}`"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ListingFilters from "../../../components/general/listing/ListingFilters.vue";
import axios from "axios";
import Listing from "@/components/general/listing/Listing.vue";
import Spinner from "../../../components/ui/Spinner.vue";

export default {
  components: {
    ListingFilters,
    Listing,
    Spinner,
  },
  props: {},
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("auth", ["getUser", "isAuth", "getUserType"]),
    listings() {
      let listings = this.getListings.data;

      let latlngs = [];

      for (let listing of listings) {
        let l = { ...listing, latlng: [listing.lat, listing.lng] };
        latlngs.push(l);
      }

      return latlngs;
    },
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      center: { lat: 52.1326, lng: 5.2913 },
      markerLatLng: [],
      suitableListings: [],
      nonSuitableListings: [],
      modalComp: "Schedule",
      listing: null,
      loading: false,
      filters: {},
    };
  },
  async created() {
    await this.getListings();
    let scriptId = "map-api-script";
    let mapAlreadyAttached = !!document.getElementById(scriptId);

    if (mapAlreadyAttached) {
      // if (window.google)
      // Script attached but may not finished loading; so check for 'google' object.
      // callback();
    } else {
      window.mapApiInitialized = this.mapInit;
      const GOOGLE_MAP_API_KEY = process.env.VUE_APP_GOOGLE_MAP_API_KEY;
      let script = document.createElement("script");
      script.id = scriptId;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&callback=mapApiInitialized&libraries=marker&v=beta`;
      document.body.appendChild(script);
    }
  },
  mounted() {},
  methods: {
    ...mapActions("listing", ["setUserPreferenceListings"]),
    mapInit() {
      console.log("mapInit", this.zoom, this.center);
      const map = new window.google.maps.Map(document.getElementById("map"), {
        center: this.center,
        zoom: this.zoom,
        mapId: "4504f8b37365c3d0",
      });

      for (let listing of this.suitableListings) {
        const priceTag = document.createElement("div");
        priceTag.className = "mapIcon suitable";
        priceTag.textContent = `€ ${listing.price}`;
        this.addMarker(map, priceTag, { lat: listing.lat, lng: listing.lng });
      }

      for (let listing of this.nonSuitableListings) {
        const priceTag = document.createElement("div");
        priceTag.className = "mapIcon nonsuitable";
        priceTag.textContent = `€ ${listing.price}`;
        this.addMarker(map, priceTag, { lat: listing.lat, lng: listing.lng }, listing);
      }
    },
    addMarker(map, content, position, listing) {
      const marker = new window.google.maps.marker.AdvancedMarkerView({
        map,
        position: position,
        content: content,
      });
      marker.addListener("click", () => {
        this.listing = listing;
      });
    },
    async getListings(data = null, loadMap = false) {
      this.loading = true;
      this.listing = null;
      if (data) {
        this.filters = { ...data };
        // houseprice
        if (this.filters.maxRent != null && this.filters.maxRent.length) {
          this.filters.maxRent = parseInt(this.filters.maxRent.replace(/\D/g, ""));
        }
        if (this.filters.municipalities) {
          this.filters.municipalities = this.filters.municipalities.map(
            (mun) => mun.value
          );
          // .join(",");
        }
        if (!this.filters.search) {
          this.filters.search = this.search;
        }
      }
      const query = { ...this.filters, limit: 100 };

      if (this.filters.municipalities && this.filters.municipalities.length) {
        this.zoom = 12;
      } else {
        this.zoom = 8;
      }

      let listings;
      ({ listings } = await this.setUserPreferenceListings({
        type: "suitable",
        query: query,
      }));
      this.suitableListings = listings.data;
      ({ listings } = await this.setUserPreferenceListings({
        type: "nonSuitable",
        query: query,
      }));
      this.nonSuitableListings = listings.data;

      this.getCoordinatesFromAddress({
        ...(this.filters.municipalities &&
          this.filters.municipalities.length && { city: this.filters.municipalities[0] }),
        country: "Netherlands",
      });

      this.loading = false;
      this.$nextTick(() => {
        if (loadMap) {
          this.mapInit();
        }
      });
    },
    async getCoordinatesFromAddress(address) {
      console.log("address", address);
      const queryObj = {
        ...address,
        limit: 1,
        format: "json",
      };
      const queryParams = new URLSearchParams(queryObj).toString();
      const url = `https://nominatim.openstreetmap.org/search?${queryParams}`;
      console.log("url", url);
      try {
        const { data } = await axios.get(url);
        if (data.length) {
          this.center = { lat: Number(data[0].lat), lng: Number(data[0].lon) };
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  },
};
</script>

<style>
.info-windows {
  width: 100vw;
  height: calc(100vh - 170px);
}

.mapIcon {
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  font-weight: 700;
  font-size: 15px;
  width: auto;
  border: 1px solid #f1f1f1;
  padding: 3px 6px;
  text-align: center;
  letter-spacing: 0px !important;
}

.mapIcon.suitable {
  color: #0a9250;
}

.mapIcon.nonsuitable {
  color: #fe5f55 !important;
}

.listingPopup {
  width: 350px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 1000;
}
</style>
